import * as React from 'react';
import Typography from '@mui/material/Typography';
import AppBarComponent from "../components/AppBarComponent";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, ButtonBase, CardActionArea, Chip,
    CircularProgress,
    Container, Divider, FormControl, InputLabel,
    ListItem, OutlinedInput, Select, Table, TableBody, TableCell, TableHead, TableRow,
    Tooltip
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useEffect, useState} from "react";
import TireRepairIcon from '@mui/icons-material/TireRepair';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Grid from "@mui/material/Grid";
import moment from 'moment-with-locales-es6';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ConstructionIcon from '@mui/icons-material/Construction';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import HandshakeIcon from '@mui/icons-material/Handshake';
import {useLocalStorage} from "../useLocalStorage";
import Paper from "@mui/material/Paper";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate, useParams} from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import Button from "@mui/material/Button";
import ShareIcon from '@mui/icons-material/Share';
import ShareMenu from "../components/ShareMenu";
import InfoIcon from '@mui/icons-material/Info';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FormControlLabel from "@mui/material/FormControlLabel";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function HomeScreen() {
    moment.locale("bg"); // it is required to select default locale manually
    let params = useParams();
    let navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    const [isCarsLoading, setCarsLoading] = useState(true);

    const [expandedItem, setExpandedItem] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isMenuOpen, setMenuOpen] = React.useState(false);
    const [isPublic, setPublic] = React.useState(false);
    const [copySuccessOpen, setCopySuccessOpen] = React.useState(false);


    const [ordersPage, setOrdersPage] = React.useState(2);

    const [cars, setCars] = React.useState([]);
    const [carsChunk, setCarsChunk] = React.useState(10);
    const [orders, setOrders] = React.useState([]);
    const [isForbidden, setForbidden] = React.useState(false);
    const [plateLogged, setPlateLogged] = useLocalStorage("plateLogged", null);
    const [vehicleHash, setVehicleHash] = useLocalStorage("vehicleHash", null);
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [bulstadLogged, setBulstadLogged] = useLocalStorage("bulstadLogged", null);
    const [bulstadOrPhoneLogged, setBulstadOrPhoneLogged] = useLocalStorage("bulstadLogged", null);
    const [bulstadLoggedOnly, setBulstadLoggedOnly] = useLocalStorage("bulstadLoggedOnly", null);

    const [vehicleHashState, setVehicleHashState] = useState(vehicleHash);
    const [vehicleAccessTokenState, setAccessTokenState] = useState(accessToken);
    const [bulstadState, setBulstatState] = useState(bulstadLoggedOnly);

    const [carBrand, setCarBrand] = useState(null);
    const [carPlate, setCarPlate] = useState(null);
    const [carLastOil, setCarLastOil] = useState(null);

    const [plateSearch, setPlateSearch] = useState(null);

    const [selectedDepartments, setSelectedDepartments] = React.useState([1, 2, 3, 4, 5, 7]);
    const [fromDate, setFromDate] = React.useState(moment());
    const [toDate, setToDate] = React.useState(moment());


    const [refresh, setRefresh] = React.useState(null);

    const [useClientIdForOrders, setUseClientIdForOrders] = React.useState(null);
    const [filteredCars, setFilteredCars] = React.useState(null);



    useEffect(() => {
        console.log(vehicleHashState)

        if (!vehicleHashState) {
            return
        }
        setLoading(true)
        fetch(`${process.env.REACT_APP_URL}/api/service-history/orders?`+ new URLSearchParams({
            plate: plateLogged,
            hash: vehicleHashState,
            ...(useClientIdForOrders ? { client_id: useClientIdForOrders } : {}),
            ...(accessToken ? { access_token: accessToken } : {} )
        }), {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                'Authorization': "3aD4Q"
            })
        })
            .then(response => response.json())
            .then(
                (result) => {
                    if (result && result.data) {
                        setOrders(result.data);
                        setForbidden(false)
                        setLoading(false)
                        setFilteredCars(null)
                    } else {
                        setForbidden(true)
                        setLoading(false)
                    }
                },
                (error) => {

                }
            );
    }, [useClientIdForOrders, vehicleHashState, refresh])


    function logOut() {
        setPlateLogged("")
        setBulstadLogged("")
        setBulstadLoggedOnly("")
        setAccessToken("")
        setVehicleHash("")
        navigate('/login')
    }

    useEffect(() => {
        if (!bulstadOrPhoneLogged) {
            setVehicleHashState(null);
            setVehicleHashState(params.hash);
            setCars([])
            setCarsLoading(false)
            return
        }
        fetch(`${process.env.REACT_APP_URL}/api/service-history/carsByBulstad?`+ new URLSearchParams({
            bulstad: bulstadOrPhoneLogged
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(
                (result) => {
                    setCarsLoading(false);
                    setCars(result);
                    if (result && result.length > 0) {
                        const car = result[0];
                        setCarBrand(car.car.make);
                        setCarPlate(car.car.licence_plate);
                        setCarLastOil(car.last_oil);
                        setPlateLogged(car.car.licence_plate);
                        setVehicleHash(car.data.hash);
                        setAccessToken(car.data.access_token);
                        setVehicleHashState(car.data.hash);
                        setAccessTokenState(car.data.access_token);
                        navigate('/vehicle/' + car.data.hash)
                    }
                },
                (error) => {
                    logOut()
                }
            );
    }, [])

    const getIcon = (departmentId) => {
        switch (departmentId) {
            case 1:
                return <TireRepairIcon fontSize={'small'}/>
            case 2:
                return <ConstructionIcon fontSize={'small'}/>
            case 3:
                return <ShoppingCartCheckoutIcon fontSize={'small'}/>
            case 4:
                return <CarRepairIcon fontSize={'small'}/>
            case 6:
                return <HandshakeIcon fontSize={'small'}/>
        }
    }

    const allDepartments = [
        {name: 'Гуми', value: 1},
        {name: 'Ремонти', value: 2},
        {name: 'Поръчка части', value: 3},
        {name: 'Реглаж', value: 4},
        {name: 'Климатик', value: 5},
        {name: 'ГТП', value: 7},
    ];

    function filterServices() {
        console.log(vehicleHashState)

        if (!vehicleHashState) {
            return
        }
        if (fromDate && !toDate) {
            const today = new Date();
            setToDate(today.toISOString().split('T')[0])
        }

        setLoading(true)
        fetch(`${process.env.REACT_APP_URL}/api/service-history/orders?`+ new URLSearchParams({
            plate: plateLogged,
            hash: vehicleHashState,
            ...(useClientIdForOrders ? { client_id: useClientIdForOrders } : {}),
            ...(accessToken ? { access_token: accessToken } : {} )
        }), {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                'Authorization': "3aD4Q",
                'department_ids': selectedDepartments && selectedDepartments.length>0 ? selectedDepartments : null,
                'from_date': fromDate && toDate ? fromDate.format('YYYY-MM-DD') : null,
                'to_date': fromDate && toDate ? toDate.format('YYYY-MM-DD') : null
            })
        })
            .then(response => response.json())
            .then(
                (result) => {
                    if (result && result.data) {
                        setOrders(result.data);
                        setForbidden(false)
                        setLoading(false)
                        filterCars(result.data)
                    } else {
                        setForbidden(true)
                        setLoading(false)
                    }
                },
                (error) => {

                }
            );
    }

    function filterCars(orders) {
        const carIds = new Set();
        if (orders) {
            for (const order of orders) {
                if (order.car_new) {
                    carIds.add(order.car_new.id)
                }
            }
        }
        setFilteredCars(Array.from(carIds))
    }

    function getDepartmentName(department) {
        if (department) {
            return department.id === 1 ? "Смяна на гуми" :
                department.id === 3 ? "Покупка на части и консумативи" :
                department.name;
        }
        return "Неизвестно"
    }

    return (
        <React.Fragment>
            <AppBarComponent
                accessToken={vehicleAccessTokenState}/>
            <br/>
          {/*  {
                isLoading &&
                <CircularProgress size={80} style={{position: 'absolute',
                    left: 0,
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto' }} />
            }*/}

                <Grid container style={{padding: 15}} justifyContent={'center'} spacing={2}
                onClick={(e) => {
                    try {
                        if (e && e.target && e.target.nextSibling && e.target.nextSibling.className &&
                            (e.target.nextSibling.className.includes('MuiSwitch') || e.target.nextSibling.className.includes('MuiTypography') )){
                            return;
                        }
                    } catch (e) {
                        return;
                    }

                    setAnchorEl(null)
                    setMenuOpen(false)
                }}>

                    {
                        (!isForbidden && accessToken) &&
                        <Grid item>
                            {
                                isCarsLoading ?
                                    <Paper elevation={6} style={{padding: 15}}><CircularProgress/></Paper>
                                    :
                                    <Paper elevation={6} style={{padding: 15}}>
                                        <Grid container alignItems={'center'}>
                                            <Tooltip title={'Автомобил'}>
                                                <DirectionsCarIcon/>
                                            </Tooltip>
                                            <TextField placeholder={'Търси автомобил'} value={plateSearch}
                                                       style={{marginLeft: 10}}
                                                       size={'small'}
                                                       onChange={(event) => {
                                                           setPlateSearch(event.target.value)
                                                       }}/>
                                        </Grid>

                                        {cars && cars.length > 0 ?
                                            <React.Fragment>
                                                { accessToken && bulstadState ? <FormControlLabel control={<Checkbox checked={!!useClientIdForOrders}
                                                                                      onChange={(event, checked) => {
                                                                                          if (checked) {
                                                                                              setUseClientIdForOrders(cars[0].car.client_id)
                                                                                          } else {
                                                                                              setUseClientIdForOrders(null)
                                                                                          }
                                                                                      }}/>} label="Избери всички"/> : null }

                                            </React.Fragment>
                                             : null}

                                        <List>
                                            {
                                                cars && cars
                                                    .slice(0, filteredCars || plateSearch ? cars.length : carsChunk)
                                                    .filter((car) => {
                                                        if (!plateSearch) {
                                                            return true;
                                                        }
                                                        if (car && car.car && car.car.licence_plate) {
                                                            return car.car.licence_plate.includes(plateSearch)
                                                        }
                                                        return true;
                                                    })
                                                    .filter((car) => {
                                                        if (filteredCars && car) {
                                                            console.log('filter ', filteredCars )
                                                            console.log('filter ', car.car.id )
                                                            return filteredCars.includes(car.car.id)
                                                        }
                                                        return true;
                                                    })
                                                    .map((car) => {
                                                    return <ListItemButton
                                                        style={!useClientIdForOrders && vehicleHash === car.data.hash ? {backgroundColor: '#30CFC4', color: 'white'} : {}}
                                                        onClick={() => {
                                                            setCarBrand(car.car.make);
                                                            setCarPlate(car.car.licence_plate);
                                                            setCarLastOil(car.last_oil);
                                                            setPlateLogged(car.car.licence_plate);
                                                            setVehicleHash(car.data.hash);
                                                            setAccessToken(car.data.access_token);
                                                            setVehicleHashState(car.data.hash);
                                                            setAccessTokenState(car.data.access_token);
                                                            navigate('/vehicle/' + car.data.hash)
                                                    }}>
                                                        <Typography style={{marginLeft: 10, textShadow: !useClientIdForOrders && vehicleHash === car.data.hash ? '1px 1px #C5C5C5' : ''}}>
                                                            {
                                                                [car.car.make, car.car.licence_plate].filter(Boolean).join(' / ')
                                                            }
                                                        </Typography>
                                                    </ListItemButton>

                                                })
                                            }
                                        </List>

                                        {
                                            !plateSearch && !filteredCars && carsChunk < cars.length ?
                                                <Button onClick={() => {
                                                    setCarsChunk(carsChunk + 10)
                                                }}>Зареди още</Button>
                                                : null
                                        }
                                    </Paper>
                            }
                        </Grid>
                    }



                    {
                        isForbidden ?
                            <Paper elevation={6} style={{padding: 15}}><Typography>Нямате право да виждате този
                                автомобил</Typography></Paper> :

                            <Grid item>
                                <Container component="main" maxWidth="md">
                                    {
                                        isLoading ?
                                            <Paper elevation={6} style={{padding: 20, minWidth: 400, minHeight: 60}}>
                                                <Grid container justifyContent={'center'}>
                                                    <Grid item>
                                                        <CircularProgress />
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                            :
                                            <React.Fragment>
                                                {accessToken &&
                                                <Paper elevation={6} style={{marginBottom: 30, backgroundColor: '#30CFC4'}}>
                                                    {isCarsLoading ?
                                                        <CircularProgress/>
                                                        :
                                                        <Grid container justifyContent={'space-between'}
                                                              alignItems={'center'}>
                                                            <Grid item xs={5} style={{margin: 20}}>
                                                                {
                                                                    useClientIdForOrders ?
                                                                        <Typography component="h1" variant="h5" style={{
                                                                            color: 'white',
                                                                            textShadow: '1px 1px #C5C5C5'
                                                                        }}>Сервизна история за всичките Ви автомобили</Typography> :
                                                                        <Typography component="h1" variant="h5" style={{
                                                                            color: 'white',
                                                                            textShadow: '1px 1px #C5C5C5'
                                                                        }}>{carBrand} / {carPlate}</Typography>
                                                                }
                                                            </Grid>
                                                            <Grid item>
                                                                <Grid container alignItems={'center'} spacing={2}>
                                                                    <Grid item>
                                                                        <ThemeProvider theme={createTheme({
                                                                            palette: {
                                                                                primary: {
                                                                                    main: 'rgb(26, 41, 65)',
                                                                                },
                                                                            }
                                                                        })}>
                                                                            <Button variant="contained" endIcon={<ShareIcon/>}
                                                                                    style={{marginRight: 20}}
                                                                                    onClick={(event) => {
                                                                                        event.stopPropagation()
                                                                                        setAnchorEl(event.currentTarget);
                                                                                        setMenuOpen(true)
                                                                                    }}>
                                                                                Споделяне
                                                                            </Button>
                                                                        </ThemeProvider>

                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>


                                                            <Grid item xs={12} style={{background: 'white'}}>
                                                                {!useClientIdForOrders && carLastOil ? <Typography style={{
                                                                        color: 'black',
                                                                        padding: 20
                                                                    }}>Пробег при последна смяна на масло на {carLastOil.dashboard_reading} км. Дата: {carLastOil.date ? moment(carLastOil.date).format('DD.MM.yyyy') : 'неизвестно'}</Typography>
                                                                    : <Typography style={{
                                                                        color: 'black',
                                                                        padding: 20
                                                                    }}>Нямаме данни за смяна на масло на този автомобил</Typography>}
                                                            </Grid>

                                                        </Grid>
                                                    }
                                                </Paper>
                                            }
                                            {accessToken && bulstadState &&
                                                <Paper elevation={6} style={{marginBottom: 30, padding: 15}}>
                                                    <Grid container alignItems={'center'} spacing={1}>
                                                        <Grid item xs={12} sm={4}>
                                                            <FormControl sx={{ m: 1, width: 250 }}>
                                                        <InputLabel id="dep-label">Услуги</InputLabel>
                                                        <Select
                                                            labelId="dep-label"
                                                            id="demo-multiple-checkbox"
                                                            multiple
                                                            value={selectedDepartments}
                                                            onChange={(event) => {
                                                                const {
                                                                    target: { value },
                                                                } = event;
                                                                setSelectedDepartments(
                                                                    // On autofill we get a stringified value.
                                                                    typeof value === 'string' ? value.split(',') : value,
                                                                );
                                                            }}
                                                            input={<OutlinedInput label="Услуга" />}
                                                            renderValue={(selected) =>
                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                    {selected.map((value) => {
                                                                        const department = allDepartments.find(e => e.value === value);
                                                                        return <Chip key={value}
                                                                                     label={department ? department.name: null}/>
                                                                    })}
                                                                </Box>}
                                                        >
                                                            {allDepartments.map((item) => (
                                                                <MenuItem key={item.name} value={item.value}>
                                                                    <Checkbox checked={selectedDepartments.find((selected) => selected === item.value)} />
                                                                    <ListItemText primary={item.name} />
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                        </Grid>

                                                        <Grid item sm={12} md={4}>
                                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                    <MobileDatePicker
                                                                        label="От"
                                                                        inputFormat="DD/MM/YYYY"
                                                                        value={fromDate}
                                                                        onChange={(value) => setFromDate(value)}
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                    />
                                                                </LocalizationProvider>
                                                        </Grid>

                                                        <Grid item sm={12} md={4}>

                                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                                <MobileDatePicker
                                                                    label="До"
                                                                    inputFormat="DD/MM/YYYY"
                                                                    value={toDate}
                                                                    onChange={(value) => setToDate(value)}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>

                                                        <Grid item xs={'auto'}>

                                                            <Button variant={'contained'} sx={{m: 1}} onClick={() => filterServices()}>Филтрирай</Button>
                                                        </Grid>

                                                        {((selectedDepartments && selectedDepartments.length > 0) || fromDate || toDate) ?
                                                        <Grid item xs={'auto'}>
                                                            <Button variant="outlined" color={'error'}
                                                                    onClick={() => {
                                                                        setSelectedDepartments([])
                                                                        setFromDate(null)
                                                                        setToDate(null)
                                                                        setRefresh(Math.random())
                                                                    }}
                                                                    startIcon={<HighlightOffIcon/>}>
                                                                Изчисти филтри
                                                            </Button>
                                                        </Grid> : null}
                                                    </Grid>
                                                </Paper>
                                            }

                                            {(vehicleHashState && !accessToken && (orders && orders.length>0 && orders[0] && orders[0].car_new)) &&
                                                <Paper elevation={6} style={{marginBottom: 30, padding: 15}}>
                                                    {isCarsLoading ?
                                                        <CircularProgress/>
                                                        :
                                                        <Grid container justifyContent={'space-between'}
                                                              alignItems={'center'} spacing={2} style={{padding: 10}}>
                                                            <Grid item xs={10}>
                                                                <Typography>Виждате сервизната история на {orders[0].car_new.make} {orders[0].car_new.model} / {orders[0].car_new.licence_plate}</Typography>
                                                            </Grid>

                                                        </Grid>
                                                    }
                                                </Paper>
                                            }
                                            {
                                                orders && orders.map((order) => {
                                                    return <CardActionArea>
                                                            <Accordion elevation={6} style={{marginBottom: 6, marginTop: 6, padding: 10,
                                                                background: expandedItem === order.id ? '#ececec' : 'white'}}
                                                                       expanded={expandedItem === order.id}
                                                                       onChange={ (event, isExpanded) => {
                                                                           setExpandedItem(isExpanded ? order.id : false);
                                                                       }} >
                                                            <AccordionSummary
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <Grid container>
                                                                    <Grid item xs={10} sm={9}>

                                                                        <Grid item xs={12}>
                                                                            <Typography variant={'h5'} style={{marginBottom: 10}}>{getDepartmentName(order.department_new)}</Typography>
                                                                        </Grid>

                                                                        <Divider light style={{margin: 10, marginLeft: 0, backgroundColor: '#30CFC4'}}/>


                                                                        <Grid item xs={12}>

                                                                            <Typography>
                                                                                <b>Дата на приемане:</b> {order && order.schedule && order.schedule.start ? moment(order.schedule.start).format("dddd DD MMMM YYYY, h:mm") :
                                                                                    order.date ? moment(order.date).format("dddd DD MMMM YYYY") :
                                                                                    'Неизвестно'}
                                                                                <Tooltip title={'Дата на приемане'} style={{marginLeft: 5}}>
                                                                                    <CalendarMonthIcon fontSize={'small'}/>
                                                                                </Tooltip>
                                                                            </Typography>
                                                                        </Grid>
                                                                        {
                                                                            order && order.car_new && order.car_new.clients &&
                                                                            <Grid item xs={12}>
                                                                                <Typography><b>Име на клиент:</b> {order.car_new.clients.name}</Typography>
                                                                            </Grid>
                                                                        }

                                                                        { useClientIdForOrders ?
                                                                                <Grid item xs={12}>
                                                                                    <Typography>
                                                                                        <b>Автомобил:</b> {order.car_new ?
                                                                                        [order.car_new.make, order.car_new.licence_plate].filter(Boolean).join(' / ')
                                                                                        :
                                                                                        "Няма информация"
                                                                                    }
                                                                                    </Typography>
                                                                                </Grid> : null
                                                                        }

                                                                        {
                                                                            order.dashboard_reading ?
                                                                                <Grid item xs={12}>
                                                                                    <Typography>
                                                                                        <b>Километраж:</b> {order.dashboard_reading} км.
                                                                                    </Typography>
                                                                                </Grid> : null
                                                                        }
                                                                    </Grid>

                                                                    <Grid item xs={12} sm={3}>
                                                                        <Grid container>
                                                                            <Grid item sm={12} sx={{ display: { xs: 'none', sm: 'none', md: 'block'} }}>
                                                                                <img style={{width: 200}}
                                                                                     title={order.warehouse_id === 1 ? 'Люлин' : 'Младост'}
                                                                                     src={order.warehouse_id === 1 ?'https://cdncloudcart.com/13012/files/image/kontaktismall.png' : 'https://cdncloudcart.com/13012/files/image/mladost-small.png' }/>

                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography variant={'h6'}>6 Плюс {order.warehouse_id === 1 ? 'Люлин' : 'Младост'}</Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                    </Grid>
                                                                    {expandedItem !== order.id ? <Grid item xs={12}>
                                                                        <Divider light/>
                                                                        <Grid container justifyContent={'center'}>
                                                                            <Grid item>
                                                                                <Grid container alignItems={'center'}>
                                                                                    <Typography style={{marginTop: 10}}>Виж
                                                                                        повече</Typography>
                                                                                </Grid>

                                                                            </Grid>

                                                                        </Grid>

                                                                    </Grid> : null}

                                                                </Grid>
                                                            </AccordionSummary>


                                                            <AccordionDetails>

                                                                {(order.products && order.products.length > 0) &&
                                                                    <Grid container style={{marginTop: 10}}>
                                                                        <Grid container>
                                                                            <Grid item xs={12}>
                                                                                <Grid container>
                                                                                    <Tooltip title={'Продукти'}>
                                                                                        <Inventory2Icon/>
                                                                                    </Tooltip>
                                                                                    <Typography style={{marginLeft: 10}}>
                                                                                        Продукти
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid item xs={12}>

                                                                                <Table size="small" aria-label="a dense table">
                                                                                    <TableHead>
                                                                                        <TableRow>
                                                                                            <TableCell><b>Име</b></TableCell>
                                                                                            <TableCell align="right"><b>Количество</b></TableCell>
                                                                                            <TableCell align="right"><b>Цена</b></TableCell>
                                                                                        </TableRow>
                                                                                    </TableHead>
                                                                                    <TableBody>
                                                                                        {order.products && order.products.map((prod) => (
                                                                                            <TableRow
                                                                                                key={prod.product.name}
                                                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                            >
                                                                                                <TableCell component="th" scope="row">
                                                                                                    {prod.product.name}
                                                                                                </TableCell>
                                                                                                <TableCell align="right">{prod.quantity}</TableCell>
                                                                                                <TableCell align="right">{prod.price} лв.</TableCell>
                                                                                            </TableRow>
                                                                                        ))}

                                                                                        <TableRow
                                                                                            key={'tot-prod'}
                                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                        >
                                                                                            <TableCell component="th" scope="row">
                                                                                                <b>Общо</b>
                                                                                            </TableCell>
                                                                                            <TableCell align="right"></TableCell>
                                                                                            <TableCell align="right">
                                                                                                {order.total_with_vat} лв.
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    </TableBody>
                                                                                </Table>
                                                                            </Grid>
                                                                        </Grid>


                                                                    </Grid>
                                                                }


                                                            </AccordionDetails>


                                                        </Accordion>
                                                    </CardActionArea>
                                                    })
                                                }
                                            </React.Fragment>

                                    }

                                    {(ordersPage && !isForbidden) && <Button variant={'contained'} size={'large'} onClick={() => {
                                        fetch(`${process.env.REACT_APP_URL}/api/service-history/orders?`+ new URLSearchParams({
                                            page: ordersPage,
                                            plate: plateLogged,
                                            hash: vehicleHashState,
                                            ...(useClientIdForOrders ? { client_id: useClientIdForOrders } : {}),
                                            ...(accessToken ? { access_token: accessToken } : {} )
                                        }), {
                                            method: "POST",
                                            mode: 'cors',
                                            headers: {
                                                "Content-Type": "application/json"
                                            },
                                            body: JSON.stringify({
                                                'Authorization': "3aD4Q"
                                            })
                                        })
                                            .then(response => response.json())
                                            .then(
                                                (result) => {
                                                    console.log(result.data)
                                                    if (result && result.data && result.data.length > 0) {
                                                        setOrders(orders.concat(result.data));
                                                        setOrdersPage(ordersPage + 1)
                                                        filterCars(orders)
                                                    } else {
                                                        setOrdersPage(null)
                                                    }
                                                },
                                                (error) => {

                                                }
                                            );
                                    }}>Зареди още</Button>}


                                </Container>
                            </Grid>
                    }

                    <ShareMenu
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        isMenuOpen={isMenuOpen}
                        isPublic={isPublic}
                        setPublic={setPublic}
                        carBrand={carBrand}
                        carPlate={carPlate}
                        accessToken={vehicleAccessTokenState}
                        copySuccessOpen={copySuccessOpen}
                        setCopySuccessOpen={setCopySuccessOpen}
                    />

                </Grid>



        </React.Fragment>
    )
}

export default HomeScreen;