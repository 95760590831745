import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import {useLocalStorage} from "../useLocalStorage";
import {useLocation, useNavigate} from "react-router-dom";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Switch from "@mui/material/Switch";

function SideMenu(props) {
    const [locationView, setLocationView] = useLocalStorage("locationView", false);
    const [plateLogged, setPlateLogged] = useLocalStorage("plateLogged", null);
    const [bulstadLogged, setBulstadLogged] = useLocalStorage("bulstadLogged", null);
    const [vehicleHash, setVehicleHash] = useLocalStorage("vehicleHash", null);
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    let navigate = useNavigate();
    const location = useLocation()

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={() => {
                props.setOpen(false)
            }}
            onKeyDown={() => {
                props.setOpen(false)
            }}
        >
            <List>

                <ListItem key={'Изход'} disablePadding>
                    <ListItemButton onClick={() => {
                        setPlateLogged("")
                        setBulstadLogged("")
                        setAccessToken("")
                        setVehicleHash("")
                        navigate('/login')
                    }}>
                        <ListItemIcon >
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Изход'} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <React.Fragment key={'left'}>
            <Drawer
                anchor={'left'}
                open={props.isOpen}
                onClose={() => {
                    props.setOpen(false)
                }}
            >
                {list('left')}
            </Drawer>
        </React.Fragment>
    );
}

export default SideMenu;
