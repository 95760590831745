import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logo from '../6plus.jpeg';
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../useLocalStorage";
import {useEffect} from "react";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import BusinessIcon from '@mui/icons-material/Business';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PinTextField from "../components/PinTextField";
import MySwal from "sweetalert2";
import {SvgIcon} from "@mui/material";
import ViberIcon from "../viber.png";

function Copyright(props) {


    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://6plus.bg/">
                6 Плюс
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();
function LoginScreen() {
    const PIN_LENGTH = 6;

    let navigate = useNavigate();
    const imageRef = React.useRef(null);

    const [isPinSent, setIsPinSent] = React.useState(false);

    const [tab, setTab] = React.useState("1");


    const [phone, setPhone] = React.useState(null);
    const [plate, setPlate] = React.useState(null);
    const [bulstad, setBulstad] = React.useState(null);

    const [pin, setPin] = React.useState(null);
    const [pinLength, setPinLength] = React.useState(0);


    const [bulstadLoggedOnly, setBulstadLoggedOnly] = useLocalStorage("bulstadLoggedOnly", null);
    const [bulstadLogged, setBulstadLogged] = useLocalStorage("bulstadLogged", null);
    const [plateLogged, setPlateLogged] = useLocalStorage("plateLogged", null);
    const [vehicleHash, setVehicleHash] = useLocalStorage("vehicleHash", null);
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    useEffect(() => {
        if (pin) {
            console.log('pin length changed to ', pin.length)
            setPinLength(pin.length)
        } else {
            console.log('pin length changed to ', 0)
            setPinLength(0)
        }
    }, [pin])

    useEffect(() => {
        if (bulstadLogged) {
            navigate('/vehicle/first')
        } else if (vehicleHash) {
            navigate('/vehicle/'+vehicleHash)
        }
    })
    const showAlert = () => {
        MySwal.fire({
            title: 'Супер!',
            html: "Изпратихме Ви линк и парола за достъп на Вайбър.",
            icon: 'success'
        }).then(function() {
            window.location = "viber://chat?number=1716";
        })
    }
    const noNumberAlert = (text) => {
        MySwal.fire({
            title: 'Номер!',
            html: "Моля, въведете вашия " + text + '.',
            icon: 'error'
        })
    }

    const telephoneLogin = <Box noValidate sx={{ mt: 1 }}>
        <TextField
            style={{minWidth: 270}}
            autoComplete={"tel"}
            placeholder={'0881234567'}
            margin="normal"
            required
            fullWidth
            id="plate"
            onChange={(event) => {
                setPhone(event.target.value);
            }}
            label="Телефонен номер"
            name="telephone"
            value={phone}
            autoFocus
        />

        {isPinSent ?
        <PinTextField
            pin={pin}
            setPin={setPin}
        /> : null }



        <Grid container justifyContent={'center'}>
            {isPinSent && <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => {
                    var re = new RegExp(/^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$/im);
                    if (re.test(phone)) {
                        console.log("Valid");
                    } else {
                        MySwal.fire({
                            title: 'Номер!',
                            html: "Номерът не е валиден. Пример за валиден номер: 0881234567",
                            icon: 'error'
                        });
                        return;
                    }

                    fetch(`${process.env.REACT_APP_URL}/api/service-history/checkPinByBulstad?` + new URLSearchParams({
                        pin: pin,
                        plate: phone
                    }), {
                        method: "GET",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(
                            (result) => {
                                if (result && result.successful) {
                                    setBulstadLogged(phone);
                                    navigate('/vehicle/first')
                                } else if (result && result.error) {
                                    if (result.error === "Pin not found.") {
                                        MySwal.fire({
                                            title: 'Парола!',
                                            html: "Грешна парола! Ако не я знаете, използвайте \"Нямаш парола?\"",
                                            icon: 'error'
                                        });
                                    }
                                }
                            },
                            (error) => {

                            }
                        );
                }}
                sx={{mt: 3, mb: 1}}
            >
                Влез
            </Button>}

{/*
            <Typography>или</Typography>
*/}

            {!isPinSent && <Button
                type="submit"
                fullWidth
                variant="contained"
                endIcon={<img src={ViberIcon} style={{width: 41.82, height: 31}}/>}
                onClick={() => {
                    if(!phone || phone.length === 0) {
                        noNumberAlert('номер')
                        return;
                    }
                    let phoneToSend = phone;

                    if (phone.startsWith('+359')) {
                        phoneToSend = phone.replace("+359", "0")
                    } else if (phone.startsWith('359')) {
                        phoneToSend = phone.replace("359", "0")
                    }

                    fetch(`${process.env.REACT_APP_URL}/api/service-history/confirmCarPinByTelephone?`+ new URLSearchParams({
                        telephone: phoneToSend
                    }), {
                        method: "GET",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(
                            (result) => {
                                if (result && result.error && result.error === "No such car") {
                                    MySwal.fire({
                                        title: 'Номер!',
                                        html: "Няма такъв телефонен номер в системата ни.",
                                        icon: 'error'
                                    });
                                    return;
                                } else if (result && result.error && result.error === "Daily limit exceed.") {
                                    MySwal.fire({
                                        title: 'Лимит!',
                                        html: "Превишен е дневният лимит за нови потребители. Опитайте пак утре!",
                                        icon: 'error'
                                    });
                                    return;
                                }
                                setIsPinSent(true)
                                showAlert()
                            },
                            (error) => {

                            }
                        );

                }}
                sx={{ mt: 1, mb: 2 }}
            >
                Вход
            </Button>}
        </Grid>


        <Copyright sx={{ mt: 5 }} />
    </Box>
    const businessLogin = <Box noValidate sx={{ mt: 1 }}>
        <TextField
            style={{minWidth: 270}}
            margin="normal"
            required
            fullWidth
            id="bulstad"
            onChange={(event) => {
                setBulstad(event.target.value);
            }}
            label="Булстад"
            name="bulstad"
            value={bulstad}
            autoFocus
        />

        {isPinSent ?
        <PinTextField
            pin={pin}
            setPin={setPin}
        /> : null }

        <Grid container justifyContent={'center'}>
            {isPinSent && <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => {
                    fetch(`${process.env.REACT_APP_URL}/api/service-history/checkPinByBulstad?` + new URLSearchParams({
                        pin: pin,
                        plate: bulstad
                    }), {
                        method: "GET",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(
                            (result) => {
                                if (result && result.successful) {
                                    setBulstadLogged(bulstad)
                                    setBulstadLoggedOnly(bulstad)
                                    navigate('/vehicle/first')
                                } else if (result && result.error) {
                                    if (result.error === "Pin not found.") {
                                        MySwal.fire({
                                            title: 'Парола!',
                                            html: "Грешна парола! Ако не я знаете, използвайте \"Нямаш парола?\"",
                                            icon: 'error'
                                        });
                                    }
                                }
                            },
                            (error) => {

                            }
                        );
                }}
                sx={{mt: 3, mb: 1}}
            >
                Влез
            </Button>}

{/*
            <Typography>или</Typography>
*/}

            {!isPinSent && <Button
                type="submit"
                fullWidth
                variant="contained"
                endIcon={<img src={ViberIcon} style={{width: 41.82, height: 31}}/>}
                onClick={() => {
                    if(!bulstad || bulstad.length === 0) {
                        noNumberAlert('булстад')
                        return;
                    }
                    fetch(`${process.env.REACT_APP_URL}/api/service-history/confirmCarPinByBulstad?`+ new URLSearchParams({
                        bulstad: bulstad
                    }), {
                        method: "GET",
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .then(response => response.json())
                        .then(
                            (result) => {
                                if (result && result.error && result.error === "No such car") {
                                    MySwal.fire({
                                        title: 'Номер!',
                                        html: "Няма такъв булстад в системата ни.",
                                        icon: 'error'
                                    });
                                    return;
                                } else if (result && result.error && result.error === "Daily limit exceed.") {
                                    MySwal.fire({
                                        title: 'Лимит!',
                                        html: "Превишен е дневният лимит за нови потребители. Опитайте пак утре!",
                                        icon: 'error'
                                    });
                                    return;
                                }
                                setIsPinSent(true)
                                showAlert()
                            },
                            (error) => {

                            }
                        );
                }}
                sx={{ mt: 1, mb: 2 }}
            >
                Вход
            </Button>}
        </Grid>

        <Copyright sx={{ mt: 5 }} />
    </Box>


    return (
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={5}
                    md={7}
                    sx={{
                        backgroundImage: `url(${logo})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={7} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            p: 1,
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5" ref={imageRef}>
                            Вход
                        </Typography>

                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={ (event, newValue) => {
                                    setTab(newValue)
                                    setPhone(null)
                                    setBulstad(null)
                                    setPin(null)
                                }} aria-label="lab API tabs example">
                                    <Tab icon={<PhoneAndroidIcon/>} label="По номер" value="1" />
                                    <Tab icon={<BusinessIcon/>} label="По булстат" value="2" />
                                    {/*
                                    <Tab icon={<TimeToLeaveIcon/>} label="По автомобил" value="3" />
*/}
                                </TabList>
                            </Box>
                            <TabPanel value="1">{telephoneLogin}</TabPanel>
                            <TabPanel value="2">{businessLogin}</TabPanel>
                        </TabContext>

                    </Box>
                </Grid>
            </Grid>
    );
}

export default LoginScreen;