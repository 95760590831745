import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";

function SimpleDialog({open, handleClose, title, text, onClick}) {
    return (
         open ? <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={() => onClick()}>Добре</Button>
            </DialogActions>
        </Dialog> : null
    );
}
export default SimpleDialog;