import React, {useEffect} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from '@mui/icons-material/Menu';
import {useLocalStorage} from "../useLocalStorage";
import SideMenu from "./SideMenu";
import {Alert, Container, Menu, Snackbar, Tooltip} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Grid from "@mui/material/Grid";
import ShareIcon from '@mui/icons-material/Share';
import AdbIcon from '@mui/icons-material/Adb';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import Looks6Icon from '@mui/icons-material/Looks6';
import ShareMenu from "./ShareMenu";
import logo from "../logo.png";
import phone from "../phone-call.png";
import Paper from "@mui/material/Paper";

const pages = ['Сервизна история', 'Оферти', 'Резервации', 'Обратна връзка', 'Изход'];
const settings = ['Профил', 'Изход'];

function AppBarComponent(props) {
    let navigate = useNavigate();

    const [auth, setAuth] = React.useState(true);
    const [isOpen, setOpen] = React.useState(false);

    const [isPublic, setPublic] = React.useState(false);
    const [copySuccessOpen, setCopySuccessOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [bulstadLogged, setBulstadLogged] = useLocalStorage("bulstadLogged", null);
    const [bulstadLoggedOnly, setBulstadLoggedOnly] = useLocalStorage("bulstadLoggedOnly", null);
    const [vehicleHash, setVehicleHash] = useLocalStorage("vehicleHash", null);
    const [plateLogged, setPlateLogged] = useLocalStorage("plateLogged", null);
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (index) => {
        setAnchorElNav(null);
        switch (index) {
            case 0:
                navigate('/')
                break;
            case 1:
                navigate('/offers')
                break;
            case 2:
                navigate('/reservations')
                break;
            case 3:
                navigate('/feedback')
                break;
            case 4:
                setPlateLogged("")
                setBulstadLogged("")
                setBulstadLoggedOnly("")
                setAccessToken("")
                setVehicleHash("")
                navigate('/login')
                break;
        }

    };

    const handleCloseUserMenu = (index) => {
        switch (index) {
            case 1:
                setPlateLogged("")
                setBulstadLogged("")
                setBulstadLoggedOnly("")
                setAccessToken("")
                setVehicleHash("")
                navigate('/login')
                break;
        }
        setAnchorElUser(null);
    };

    useEffect(() => {
        if (!props.accessToken) {
            return
        }
        fetch(`${process.env.REACT_APP_URL}/api/service-history/userInfo?`+ new URLSearchParams({
            access_token: props.accessToken
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(
                (result) => {
                    setPublic(result.is_public === 1)
                },
                (error) => {

                }
            );
    }, [props.accessToken])

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const isMenuOpen = Boolean(anchorEl);

    const renderMenu = (
        <ShareMenu
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            isMenuOpen={isMenuOpen}
            isPublic={isPublic}
            setPublic={setPublic}
            accessToken={props.accessToken}
            copySuccessOpen={copySuccessOpen}
            setCopySuccessOpen={setCopySuccessOpen}
        />
    );

    return (
        <Paper style={{margin: 10}} elevation={6}>

            <AppBar position="static" >

            <SideMenu
                isOpen={isOpen}
                setOpen={setOpen}
                setView={props.setView}/>

            <Toolbar style={{background: 'white'}}>
        {/*        { plateLogged &&
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => {
                            setOpen(true)
                        }}
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                }*/}
                <Container maxWidth="xl" style={{padding: 0}}>
                    <Toolbar disableGutters style={{padding: 3}}>
                        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <img style={{width: 80, height: 80, marginRight: 5, cursor: 'pointer'}} src={logo} onClick={() => {
                                navigate('/')
                            }}/>
                        </Box>
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <img style={{width: 60, height: 60, marginRight: 3, cursor: 'pointer'}} src={logo} onClick={() => {
                                navigate('/')
                            }}/>
                        </Box>

                        {props.accessToken ?
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon style={{color: '#30CFC4'}}/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page, index) => (
                                    <MenuItem style={{color: '#4CA69F'}} key={page} onClick={() => handleCloseNavMenu(index)}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box> : null}

                        {props.accessToken &&
                            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page, index) => (
                                <Button
                                    key={page}
                                    style={{color: '#4CA69F'}}
                                    onClick={() => handleCloseNavMenu(index)}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    <b>{page}</b>
                                </Button>
                            ))}
                        </Box> }

                        {props.accessToken &&
                        <Box sx={{ flexGrow: 0 }}>
                          {/*  <Tooltip title="Отворете настройки">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Tooltip>*/}
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting, index) => (
                                    <MenuItem key={setting} onClick={() => handleCloseUserMenu(index)}>
                                        <Typography style={{color: 'black'}} textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        }

                        <Grid item xs={10} sm={8}>
                            <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>

                                <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    <img src={phone} style={{width: 42, height: 42}}/>
                                </Grid>

                                <Grid item sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    <img src={phone} style={{width: 36, height: 36}}/>
                                </Grid>


                                <Grid item xs={8} sm={'auto'}>
                                    <Grid coontainer justifyContent="flex-end">
                                        <Typography style={{color: 'black'}}>За въпроси и поръчки</Typography>
                                        <a href="tel:+359882606606" style={{color: '#ffd200', textDecoration: 'none'}}>
                                            <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                                                <Typography variant="h5" noWrap>
                                                    0882 606 606
                                                </Typography>
                                            </Grid>
                                            <Grid item sx={{ display: { xs: 'block', sm: 'none' } }}>
                                                <Typography variant="h6" noWrap>
                                                    0882 606 606
                                                </Typography>
                                            </Grid>
                                        </a>
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>
                    </Toolbar>
                </Container>

                {/*{
                    (!props.forceDisableShare && props.accessToken) && <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={'primary-search-account-menu-mobile'}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                    >
                        <ShareIcon />
                    </IconButton>
                }*/}

                {renderMenu}
            </Toolbar>
        </AppBar>
        </Paper>
    );
}

export default AppBarComponent;
