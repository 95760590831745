import React from 'react';
import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import MySwal from "sweetalert2";
import {useLocalStorage} from "../useLocalStorage";

function AutoLoginScreen() {
    function useQuery() {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let navigate = useNavigate();

    let query = useQuery();
    const [bulstadLoggedOnly, setBulstadLoggedOnly] = useLocalStorage("bulstadLoggedOnly", null);
    const [bulstadLogged, setBulstadLogged] = useLocalStorage("bulstadLogged", null);

    function phoneLogin(pin, phone) {
        fetch(`${process.env.REACT_APP_URL}/api/service-history/checkPinByBulstad?`+ new URLSearchParams({
            pin: pin,
            plate: phone
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(
                (result) => {
                    if (result && result.successful) {
                        setBulstadLogged(phone);
                        navigate('/vehicle/first')
                    } else if (result && result.error) {
                        if (result.error === "Pin not found.") {
                            MySwal.fire({
                                title: 'Парола!',
                                html: "Грешна парола! Ако не я знаете, използвайте \"Нямаш парола?\"",
                                icon: 'error'
                            });
                        }
                    }
                },
                (error) => {

                }
            );
    }

    function uicLogin(pin, bulstad) {
        fetch(`${process.env.REACT_APP_URL}/api/service-history/checkPinByBulstad?`+ new URLSearchParams({
            pin: pin,
            plate: bulstad
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(
                (result) => {
                    if (result && result.successful) {
                        setBulstadLogged(bulstad)
                        setBulstadLoggedOnly(bulstad)
                        navigate('/vehicle/first')
                    } else if (result && result.error) {
                        if (result.error === "Pin not found.") {
                            MySwal.fire({
                                title: 'Парола!',
                                html: "Грешна парола! Ако не я знаете, използвайте \"Нямаш парола?\"",
                                icon: 'error'
                            });
                        }
                    }
                },
                (error) => {

                }
            );
    }

    useEffect(() => {
        if (query.get('type') && query.get('pin') && query.get('plate')){
            if (query.get('type') === 'phone') {
                phoneLogin(query.get('pin'), query.get('plate'))
            } else if(query.get('type') === 'uic') {
                uicLogin(query.get('pin'), query.get('plate'))
            }
        }
    }, [])

    return (
        <div>Зареждам...</div>
    )
}
export default AutoLoginScreen;