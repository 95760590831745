import LoginScreen from "./screens/LoginScreen";
import {Navigate, Route, Routes} from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import CarsScreen from "./screens/CarsScreen";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import OffersScreen from "./screens/OffersScreen";
import ReservationsScreen from "./screens/ReservationsScreen";
import AutoLoginScreen from "./screens/AutoLoginScreen";
import FeedbackScreen from "./screens/FeedbackScreen";

function App() {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#30cfc4',
                contrastText: 'white',
                contrastTextShadow: '1px 1px #C5C5C5'
            },
        },
        overrides: {
            MuiButton: {
                contained: {
                    color: 'white',
                },
            },
        }
    });

  return (
      <ThemeProvider theme={theme}>

          <div className="App">
            <Routes>
              <Route exact path="/" element={<Navigate to="/login"/>} />
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/cars" element={<CarsScreen />} />
              <Route path="/vehicle/:hash" element={<HomeScreen />} />
              <Route path="/offers" element={<OffersScreen />} />
              <Route path="/reservations" element={<ReservationsScreen />} />
              <Route path="/autologin" element={<AutoLoginScreen />} />
              <Route path="/feedback" element={<FeedbackScreen />} />
            </Routes>
          </div>
      </ThemeProvider>
  );
}

export default App;
