import * as React from 'react';
import Typography from '@mui/material/Typography';
import AppBarComponent from "../components/AppBarComponent";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CircularProgress,
    Container,
    ListItem, Table, TableBody, TableCell, TableHead, TableRow,
    Tooltip
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useEffect, useState} from "react";
import TireRepairIcon from '@mui/icons-material/TireRepair';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Grid from "@mui/material/Grid";
import moment from 'moment-with-locales-es6';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ConstructionIcon from '@mui/icons-material/Construction';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import HandshakeIcon from '@mui/icons-material/Handshake';
import {useLocalStorage} from "../useLocalStorage";
import Paper from "@mui/material/Paper";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate, useParams} from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import Button from "@mui/material/Button";
import ShareIcon from '@mui/icons-material/Share';
import ShareMenu from "../components/ShareMenu";
import InfoIcon from '@mui/icons-material/Info';
import TextField from "@mui/material/TextField";

function OffersScreen() {
    moment.locale("bg"); // it is required to select default locale manually
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [vehicleAccessTokenState, setAccessTokenState] = useState(accessToken);

    return (
        <React.Fragment>
            <AppBarComponent
                accessToken={vehicleAccessTokenState}/>
            <br/>

            <Grid container alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={4}>
                    <Paper elevation={6} style={{padding: 10}}>
                        <Typography style={{textAlign: 'center'}}>Все още нямаме оферти за Вас!</Typography>
                    </Paper>
                </Grid>
            </Grid>


        </React.Fragment>
    )
}

export default OffersScreen;