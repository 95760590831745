import AppBarComponent from "../components/AppBarComponent";
import * as React from "react";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import {useLocalStorage} from "../useLocalStorage";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import {CircularProgress} from "@mui/material";

function CarsScreen() {
    let navigate = useNavigate();

    const [bulstadLogged, setBulstadLogged] = useLocalStorage("bulstadLogged", null);
    const [plateLogged, setPlateLogged] = useLocalStorage("plateLogged", null);
    const [vehicleHash, setVehicleHash] = useLocalStorage("vehicleHash", null);
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [isLoading, setLoading] = useState(true);
    const [cars, setCars] = useState([]);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/api/service-history/carsByBulstad?`+ new URLSearchParams({
            bulstad: bulstadLogged
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(
                (result) => {
                    setLoading(false);
                    setCars(result);
                },
                (error) => {

                }
            );
    }, [])
    return (
        <React.Fragment>
            <AppBarComponent forceDisableShare/>
            <br/>
            {
                isLoading &&
                <CircularProgress size={80} style={{position: 'absolute',
                    left: 0,
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto' }} />
            }
            <Grid container style={{padding: 10}} justifyContent={'center'} spacing={2}>
                {
                    cars && cars.map((car) => {
                        return <Grid item xs={3}>
                            <Paper elevation={6} style={{padding: 10, cursor: 'grab'}} onClick={() => {
                                setPlateLogged(car.car.licence_plate);
                                setVehicleHash(car.data.hash);
                                setAccessToken(car.data.access_token);
                                navigate('/vehicle/'+car.data.hash)
                            }}>
                                <Typography>{car.car.make}</Typography>
                                <Typography>{car.car.model}</Typography>
                                <Typography>{car.car.licence_plate}</Typography>
                            </Paper>
                        </Grid>
                    })
                }

            </Grid>
        </React.Fragment>
    );
}

export default CarsScreen;