import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBarComponent from "../components/AppBarComponent";
import TireRepairIcon from '@mui/icons-material/TireRepair';
import moment from 'moment-with-locales-es6';
import ConstructionIcon from '@mui/icons-material/Construction';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import HandshakeIcon from '@mui/icons-material/Handshake';
import {useLocalStorage} from "../useLocalStorage";
import {useNavigate, useParams} from "react-router-dom";
import Paper from "@mui/material/Paper";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary, CircularProgress,
    Table, TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import Button from "@mui/material/Button";

function ReservationsScreen() {
    moment.locale("bg"); // it is required to select default locale manually
    let params = useParams();
    let navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);

    const [isForbidden, setForbidden] = React.useState(false);
    const [plateLogged, setPlateLogged] = useLocalStorage("plateLogged", null);
    const [vehicleHash, setVehicleHash] = useLocalStorage("vehicleHash", null);
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);

    const [vehicleHashState, setVehicleHashState] = useState(vehicleHash);
    const [vehicleAccessTokenState, setAccessTokenState] = useState(accessToken);


    const [futureOrders, setFutureOrders] = useState([]);


    useEffect(() => {
        if (!vehicleHashState) {
            return
        }
        setLoading(true)
        fetch(`${process.env.REACT_APP_URL}/api/service-history/ordersCurrent?`+ new URLSearchParams({
            plate: plateLogged,
            hash: vehicleHashState,
            ...(accessToken ? { access_token: accessToken } : {} )
        }), {
            method: "POST",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(
                (result) => {
                    if (result && result) {
                        setFutureOrders(result)
                        setForbidden(false)
                        setLoading(false)

                    } else {
                        setForbidden(true)
                        setLoading(false)
                    }
                },
                (error) => {

                }
            );
    }, [vehicleHashState])



    const getIcon = (departmentId) => {
        switch (departmentId) {
            case 1:
                return <TireRepairIcon fontSize={'small'}/>
            case 2:
                return <ConstructionIcon fontSize={'small'}/>
            case 3:
                return <ShoppingCartCheckoutIcon fontSize={'small'}/>
            case 4:
                return <CarRepairIcon fontSize={'small'}/>
            case 6:
                return <HandshakeIcon fontSize={'small'}/>
        }
    }

    return (
        <React.Fragment>
            <AppBarComponent
                accessToken={vehicleAccessTokenState}/>
            <br/>


            <Grid container justifyContent={'center'} spacing={2}>
                <Grid item>
                    <Paper elevation={6} style={{padding: 10}}>
                        <Button variant={'contained'} onClick={() => {
                            window.open('https://reservation.6plus.bg/?utm_source=servicehistory', '_blank').focus();
                        }}>
                            Създай резервация
                        </Button>
                    </Paper>
                </Grid>

                <Grid item>

                    <Paper elevation={6} style={{padding: 10}}>
                        {
                            isLoading &&
                            <CircularProgress size={80} style={{position: 'absolute',
                                left: 0,
                                right: 0,
                                marginLeft: 'auto',
                                marginRight: 'auto' }} />
                        }

                        {
                            !isLoading && futureOrders &&
                                <Typography>Вашите предстоящи поръчки</Typography>
                        }
                        {
                            !isLoading && futureOrders && futureOrders.map((order) => {
                                return <Accordion style={{marginBottom: 6, marginTop: 6, padding: 10}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Grid container alignItems={'center'}>
                                            <Grid item xs={12}>
                                                <Typography variant={'h5'}>6 Плюс {order.warehouse_id === 1 ? 'Люлин' : 'Младост'}</Typography>
                                            </Grid>

                                            <Grid item xs={8}>

                                                {
                                                    order && order.car_new && order.car_new.clients &&
                                                    <Grid item xs={12}>
                                                        <Typography><b>Име на клиент:</b> {order.car_new.clients.name}</Typography>
                                                    </Grid>
                                                }
                                                {
                                                    order && order.department_new && order.department_new &&
                                                    <Grid item xs={12}>
                                                        <Typography>
                                                            <b>Вид на ремонта:</b> {order.department_new.name} {getIcon(order.department_id)}
                                                        </Typography>
                                                    </Grid>
                                                }

                                                <Grid item xs={12}>

                                                    <Typography>
                                                        <b>Дата на приемане:</b> {order.date ? moment(order.date).format("dddd DD MMMM YYYY, h:mm") : null}
                                                        <Tooltip title={'Дата на приемане'} style={{marginLeft: 5}}>
                                                            <CalendarMonthIcon fontSize={'small'}/>
                                                        </Tooltip>
                                                    </Typography>
                                                </Grid>

                                                {
                                                    order.dashboard_reading ?
                                                        <Grid item xs={12}>
                                                            <Typography>
                                                                <b>Километраж:</b> {order.dashboard_reading} км.
                                                            </Typography>
                                                        </Grid> : null
                                                }
                                            </Grid>

                                            <Grid item xs={4}>
                                                <img style={{width: 200}}
                                                     title={order.warehouse_id === 1 ? 'Люлин' : 'Младост'}
                                                     src={order.warehouse_id === 1 ?'https://cdncloudcart.com/13012/files/image/kontaktismall.png' : 'https://cdncloudcart.com/13012/files/image/mladost-small.png' }/>
                                            </Grid>

                                        </Grid>
                                    </AccordionSummary>


                                    <AccordionDetails>

                                        {(order.products && order.products.length > 0) &&
                                            <Grid container style={{marginTop: 10}}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Tooltip title={'Продукти'}>
                                                                <Inventory2Icon/>
                                                            </Tooltip>
                                                            <Typography style={{marginLeft: 10}}>
                                                                Продукти
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid item xs={12}>

                                                        <Table size="small" aria-label="a dense table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell><b>Име</b></TableCell>
                                                                    <TableCell align="right"><b>Количество</b></TableCell>
                                                                    <TableCell align="right"><b>Цена</b></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {order.products && order.products.map((prod) => (
                                                                    <TableRow
                                                                        key={prod.product.name}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell component="th" scope="row">
                                                                            {prod.product.name}
                                                                        </TableCell>
                                                                        <TableCell align="right">{prod.quantity}</TableCell>
                                                                        <TableCell align="right">{prod.price} лв.</TableCell>
                                                                    </TableRow>
                                                                ))}

                                                                <TableRow
                                                                    key={'tot-prod'}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        <b>Общо</b>
                                                                    </TableCell>
                                                                    <TableCell align="right"></TableCell>
                                                                    <TableCell align="right">
                                                                        {order.total_with_vat} лв.
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                        }


                                    </AccordionDetails>


                                </Accordion>
                            })
                        }

                        {
                            !isLoading && (!futureOrders || futureOrders.length === 0) &&
                            <Typography>Нямате предстоящи поръчки</Typography>

                        }

                    </Paper>
                </Grid>

            </Grid>




            </React.Fragment>
    )
}

export default ReservationsScreen;