import TextField from "@mui/material/TextField";
import * as React from "react";

function PinTextField({pin, setPin}) {
    return (
        <TextField
            margin="normal"
            required
            fullWidth
            name="pin"
            label="Парола"
            onChange={(event) => {
                console.log('pin ', event.target.value)
                setPin(event.target.value);
            }}
            value={pin}
            type="password"
            id="pin"
        />
    )
}
export default PinTextField;