import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import {
    Alert, Dialog,
    DialogTitle,
    FormControl,
    FormHelperText,
    Select,
    Snackbar,
    Tooltip
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import React, {useEffect} from "react";
import MySwal from "sweetalert2";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';

function ShareMenu({anchorEl, setAnchorEl, isMenuOpen, isPublic, setPublic, accessToken,
                       carBrand, carPlate,
                       copySuccessOpen, setCopySuccessOpen}) {

    useEffect(() => {
        if (!accessToken) {
            return
        }
        fetch(`${process.env.REACT_APP_URL}/api/service-history/userInfo?`+ new URLSearchParams({
            access_token: accessToken
        }), {
            method: "GET",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(response => response.json())
            .then(
                (result) => {
                    setPublic(result.is_public === 1)
                },
                (error) => {

                }
            );
    }, [accessToken])

    function handleChange(event) {
        event.stopPropagation()
        let newValue = !isPublic;
        setPublic(newValue)
        fetch(`${process.env.REACT_APP_URL}/api/service-history/userInfo?`+ new URLSearchParams({
            access_token: accessToken
        }), {
            method: "PUT",
            mode: 'cors',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                is_public: newValue ? '1' : '0'
            })
        })
            .then(response => response.json())
            .then(
                (result) => {
                    MySwal.fire({
                        title: 'Супер!',
                        html: newValue ? 'Колата е споделена' : 'Колата вече НЕ е споделена',
                        icon: 'success',
                        customClass: {
                            container: {'z-index': 999999}
                        },
                        target: document.getElementById('form-modal-share')
                    })
                },
                (error) => {

                }
            );
    }

    return (
        <React.Fragment id={'form-modal-share'}>
            <Snackbar open={copySuccessOpen} autoHideDuration={3000} onClose={() => setCopySuccessOpen(false)}>
                <Alert onClose={() => setCopySuccessOpen(false)} severity="success" sx={{ width: '100%' }}>
                    Успешно копирахте линка!
                </Alert>
            </Snackbar>
            <Dialog
                // anchorEl={anchorEl}
                // anchorOrigin={{
                //     vertical: 'top',
                //     horizontal: 'left',
                // }}
                id={'menuId'}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={{zIndex: 1000}}
                open={isMenuOpen}
                onClose={() => setAnchorEl(null)}
            >
                <Grid container spacing={1} style={{maxWidth: 640, padding: 10}} justifyContent={'center'} onClick={(e) => {
                    e.stopPropagation()
                    e.nativeEvent.stopImmediatePropagation();
                }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item xs={10}>
                                <DialogTitle>Сподели {carBrand} / {carPlate}</DialogTitle>
                            </Grid>
                            <Grid item xs={'auto'}>
                                <Tooltip title={'Можете да споделите уникален линк за автомобила с други хора, за да покажете сервизната си история. Трябва само да активирате опцията "Колата е публична"'}>
                                    <IconButton>
                                        <HelpOutlineIcon style={{color: 'black'}}/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item xs={'auto'}>
                        <Typography>Кой има достъп до сервизната история?</Typography>
                    </Grid>
                    <Grid item xs={12}></Grid>

                    <Grid item xs={8}>
                        <Grid container spacing={2} alignItems={'center'}>
                            <Grid item xs={1.5}>
                                {
                                    isPublic ?
                                        <PublicTwoToneIcon style={{color: 'green'}} fontSize={'large'}/>
                                        :
                                        <LockTwoToneIcon style={{color: '#FF0000'}} fontSize={'large'}/>

                                }
                            </Grid>
                            <Grid item xs={10.5}>
                                <FormControl fullWidth>
                                    <Select
                                        id="who-access-select"
                                        value={isPublic ? 1 : 0}
                                        label=""
                                        onChange={(event) => {handleChange(event)}}
                                    >
                                        <MenuItem value={0}>
                                            Само аз
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            Всеки с връзката
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid item xs={8}>

                        <FormHelperText>
                            {isPublic ?
                                `Всеки, който има достъп до връзката може да\n види сервизната история на ${carBrand} / ${carPlate}.`
                                :
                                `Само Вие имате достъп до сервизната история на ${carBrand} / ${carPlate}.\n Дори да сте споделяли връзката с други хора, те няма да виждат вашата сервизна история.`
                            }
                        </FormHelperText>
                    </Grid>

                    {
                        false &&                     <Grid item xs={12}>
                            <FormControlLabel style={{margin: 10, marginBottom: 0}} control={<Switch checked={isPublic} onChange={(event) => {
                                event.stopPropagation()
                                let newValue = !isPublic;
                                setPublic(newValue)
                                fetch(`${process.env.REACT_APP_URL}/api/service-history/userInfo?`+ new URLSearchParams({
                                    access_token: accessToken
                                }), {
                                    method: "PUT",
                                    mode: 'cors',
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    body: JSON.stringify({
                                        is_public: newValue ? '1' : '0'
                                    })
                                })
                                    .then(response => response.json())
                                    .then(
                                        (result) => {
                                            MySwal.fire({
                                                title: 'Супер!',
                                                html: newValue ? 'Колата е споделена' : 'Колата вече НЕ е споделена',
                                                timer: 1500,
                                                icon: 'success'
                                            })
                                        },
                                        (error) => {

                                        }
                                    );
                            }} />} label="Колата е публична" />
                        </Grid>
                    }
                    {
                        isPublic && <Grid item xs={12}>
                            <Grid container alignItems={'center'} style={{margin: 10, padding: 10}} spacing={1}>
                                <Grid item xs={'auto'}>
                                    <TextField
                                        onClick={(e) => {
                                            console.log('click')
                                            e.target.select()
                                        }}
                                        style={{width: 300 , '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.38)'}}
                                        size={'small'}
                                        value={window.location.href}/>
                                </Grid>
                                <Grid item xs={5}>
                                    <Tooltip title={"Копирай"} style={{marginLeft: 10}}>
                                        <Button variant="outlined"
                                                startIcon={<ContentCopyIcon/>}
                                                onClick={() => {
                                                    navigator.clipboard.writeText(window.location.href.toString())
                                                    setCopySuccessOpen(true)
                                                }}>Копирай връзката</Button>

                                    </Tooltip>
                                </Grid>


                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Dialog>
        </React.Fragment>

    )
}
export default ShareMenu;