import * as React from 'react';
import Grid from '@mui/material/Grid';
import AppBarComponent from "../components/AppBarComponent";
import {useState} from "react";
import {useLocalStorage} from "../useLocalStorage";
import {Paper} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SimpleDialog from "../components/dialogs/SimpleDialog";
import {useNavigate} from "react-router-dom";

function FeedbackScreen() {
    let navigate = useNavigate();

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null);
    const [vehicleAccessTokenState, setAccessTokenState] = useState(accessToken);

    const [simpleDialogOpen, setSimpleDialogOpen] = useState(null);
    const [simpleDialogInputOpen, setSimpleDialogInputOpen] = useState(null);
    const [simpleDialogErrorOpen, setSimpleDialogErrorOpen] = useState(null);

    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [comment, setComment] = useState(null);

    return (
            <React.Fragment>
                <AppBarComponent accessToken={vehicleAccessTokenState}/>
                <br/>

                <Grid container justifyContent={'center'}>
                    <Grid item xs={6}>
                        <Paper elevation={6} style={{padding: 10}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField label={"Email"} fullWidth
                                               inputProps={{ maxLength: 60 }}
                                               type={'email'} value={email} onChange={(e) => setEmail(e.target.value)}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        inputProps={{ maxLength: 20 }}
                                        label={"Телефонен номер"} fullWidth type={'tel'} value={phone} onChange={(e) => setPhone(e.target.value)}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={"Коментар"} fullWidth
                                               value={comment}
                                               inputProps={{ maxLength: 800 }}
                                               onChange={(e) => setComment(e.target.value)}
                                               multiline
                                               rows={4}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant={'contained'} onClick={() => {
                                        if (!email || !comment) {
                                            setSimpleDialogInputOpen(true);
                                            return;
                                        }
                                        fetch(`${process.env.REACT_APP_URL}/api/service-history/feedback?`, {
                                            method: "POST",
                                            mode: 'cors',
                                            headers: {
                                                "Content-Type": "application/json"
                                            },
                                            body: JSON.stringify({
                                                'email': email,
                                                'phone': phone,
                                                'comment': comment,
                                                'Authorization': "3aD4Q"
                                            })
                                        })
                                            .then(response => response.json())
                                            .then(
                                                (result) => {
                                                    setSimpleDialogOpen(true)
                                                },
                                                (error) => {
                                                    setSimpleDialogErrorOpen(true)
                                                }
                                            );
                                    }}>Изпрати</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                </Grid>


                <SimpleDialog
                    open={simpleDialogOpen}
                    handleClose={() => setSimpleDialogOpen(false)}
                    title={' '}
                    text={'Благодарим за вашата обратна връзка! Тя е изпратена успешно.'}
                    onClick={() => {
                        navigate('/')
                        setSimpleDialogOpen(false)
                    }}
                />

                <SimpleDialog
                    open={simpleDialogErrorOpen}
                    handleClose={() => setSimpleDialogErrorOpen(false)}
                    title={'Грешка'}
                    text={'Възникна грешка! Моля, опитайте пак. Ако грешката остава, моля, позвънете ни на 0882 606 606.'}
                    onClick={() => {
                        setSimpleDialogErrorOpen(false)
                    }}
                />

                <SimpleDialog
                    open={simpleDialogInputOpen}
                    handleClose={() => setSimpleDialogInputOpen(false)}
                    title={'Грешка'}
                    text={'Моля попълнете имейл и коментар.'}
                    onClick={() => {
                        setSimpleDialogInputOpen(false)
                    }}
                />

            </React.Fragment>
    );
}

export default FeedbackScreen;